<script setup>
    import { inject, computed, ref } from "vue";
    import { InjectionKeys } from 'o365-utils';
    import ODeveloperMenu from "o365.vue.components.ODeveloperMenu.vue";
    import AppButton from 'o365.pwa.vue.components.layout.AppButton.vue';

    const { pwaStoreKey } = InjectionKeys;

    const { state: offlineState, setDebugUi, setDebugMergeProc, clearOfflineDataProc, setSkipCheckIn } = inject(pwaStoreKey);

    const runningOfflineDataProc = ref(false);

    const debugPwaUi = computed({
        get() {
            return offlineState?.pwaState?.debugUi ?? false;
        },
        set(value) {
            if (!offlineState?.pwaState) {
                return;
            }

            setDebugUi(value);
        }
    });

    const debugMergeProc = computed({
        get() {
            return offlineState?.pwaState?.debugMergeProc ?? false;
        },
        set(value) {
            if (!offlineState?.pwaState) {
                return;
            }

            setDebugMergeProc(value);
        }
    });

    const skipCheckIn = computed({
        get() {
            return offlineState?.pwaState?.skipCheckIn ?? false;
        },
        set(value) {
            if (!offlineState?.pwaState) {
                return;
            }

            setSkipCheckIn(value);
        }
    });

    const runCleanProc = async () => {
        runningOfflineDataProc.value = true;

        try {
            await clearOfflineDataProc();
        } catch (e){
            console.error(e);
        }

        runningOfflineDataProc.value = false;
        
        return;
    }
</script>

<template>
    
</template>